import moment from 'moment';
import Resource from './Resource';
import ShiftRules from './ShiftRules';

const timeFormat = 'HH:mm';

export default class Shift extends Resource {
  static collectionKey = 'shifts';

  constructor(id, day, name, start, end, prebook, mealDuration, bookable, notes, rules) {
    super(id);
    this._day = day;
    this._name = name;
    this._start = start;
    this._end = end;
    this._prebook = prebook;
    this._mealDuration = mealDuration;
    this._bookable = bookable;
    this._notes = notes;
    this._rules = rules;
  }

  get day() {
    return this._day;
  }

  get name() {
    return this._name;
  }

  get start() {
    return this._start;
  }

  get end() {
    return this._end;
  }

  get startTime() {
    return moment.utc(this._start * 1000).format(timeFormat);
  }

  get endTime() {
    return moment.utc(this._end * 1000).format(timeFormat);
  }

  get mealDuration() {
    return this._mealDuration;
  }

  get prebook() {
    return this._prebook;
  }

  get bookable() {
    return this._bookable;
  }

  get notes() {
    return this._notes;
  }

  get rules() {
    return this._rules;
  }

  static fromAPI(object) {
    return new Shift(
      `${object.day} ${object.start} ${object.end}`,
      object.day,
      object.name,
      object.start,
      object.end,
      object.prebook,
      object.meal_duration,
      object.bookable,
      object.notes,
      object.rules ? ShiftRules.fromAPI(object.rules) : null,
    );
  }

  static plain(object) {
    return {
      id: `${object.day} ${object.start} ${object.end}`,
      day: object.day,
      name: object.name,
      start: object.start,
      end: object.end,
      prebook: object.prebook,
      mealDuration: object.meal_duration,
      bookable: object.bookable,
      notes: object.notes,
      rules: object.rules ? {
        perPerson: object.rules.per_person ? {
          min: object.rules.per_person.min,
          noShowFee: object.rules.per_person.no_show_fee,
          lateCancellationFee: object.rules.per_person.late_cancellation_fee,
          lateCancellationThreshold: object.rules.per_person.late_cancellation_threshold,
        } : null,
        perReservation: object.rules.per_reservation ? {
          min: object.rules.per_reservation.min,
          noShowFee: object.rules.per_reservation.no_show_fee,
          lateCancellationFee: object.rules.per_reservation.late_cancellation_fee,
          lateCancellationThreshold: object.rules.per_reservation.late_cancellation_threshold,
        } : null,
      } : null,
    };
  }
}
