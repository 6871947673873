import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ButtonWrapper } from '@dineoutltd/components';
import * as styles from './style.module.scss';
import Counter from '../FormElement/Counter';
import Tooltip from '../Tooltip';
import BookingFormFields from './BookingFormFields';
import DatePicker from '../DatePicker';
import RequestService from '../../networking/RequestService';
import { timeFormat } from '../../utilities/constants';
import User from '../SVGComponents/User';
import Button from '../Button';
import LanguageSwitcher from '../LanguageSwitcher';
import Place from '../../networking/resources/Place';
import ShiftNotesAndRules from '../ShiftNotesAndRules';
import Shift from '../../networking/resources/Shift';

let slotsRequest = null;
export default function BookRestaurantForm({
  restaurantId,
  restaurantInfo,
  lang,
}) {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [seating, setSeating] = useState(false);
  const [suitableSalon, setSuitableSalon] = React.useState(null);
  const [salons, setSalons] = React.useState([]);
  const [slots, setSlots] = React.useState([]);
  const [onlyInside, setOnlyInside] = useState(false);
  const [onlyOutside, setOnlyOutside] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [booking, setBooking] = useState({});
  const [shifts, setShifts] = useState(
    restaurantInfo.shifts.map((shift) => Shift.plain(shift)) || [],
  );

  useEffect(() => {
    (new RequestService('client/places/salons'))
      .setParams({
        place_id: restaurantId,
      })
      .send()
      .then((response) => {
        setSalons(response.data);
      })
      .catch(() => null);
  }, [restaurantId]);

  useEffect(() => {
    if (salons.length > 0) {
      const outsideSalons = salons.filter((salon) => salon.outside);
      setOnlyInside(!outsideSalons.length);
      setOnlyOutside(salons.length === outsideSalons.length);
    }
  }, [salons]);

  useEffect(() => {
    if (salons.length > 0) {
      const suitableSalons = salons.filter((salon) => salon.outside === seating);
      setSuitableSalon(suitableSalons[0]);
    }
  }, [seating, salons]);

  useEffect(() => {
    if (onlyOutside) {
      setSeating(true);
    }
  }, [onlyOutside]);

  useEffect(() => {
    if (!suitableSalon || !date || !count) return;
    const currentDay = date.day();
    const currentDayShifts = restaurantInfo.shifts.filter((shift) => shift.day === currentDay);
    const startTime = Math.min(...currentDayShifts.map((shift) => shift.start));
    const endTime = Math.max(...currentDayShifts.map((shift) => shift.end));

    if (Math.max(...currentDayShifts.map((shift) => shift.bookable)) === 0
      || currentDayShifts.length === 0) {
      setSlots([]);
      setLoading(false);
      return;
    }

    const slotsParams = {
      place_id: restaurantId,
      start: moment.utc(startTime * 1000).format('HH:mm'),
      end: moment.utc(endTime * 1000).format('HH:mm'),
      date: date.format('YYYY-MM-DD'),
      interval: 30,
      people: count,
      outside: seating,
    };

    setLoading(true);
    if (slotsRequest !== null) {
      slotsRequest.source.cancel();
    }
    slotsRequest = (new RequestService('client/places/slots'))
      .setParams(slotsParams);
    slotsRequest
      .send()
      .then((response) => {
        setSlots(response.data);
        setLoading(false);
      })
      .catch(() => null);
  }, [date, count, suitableSalon]);

  useEffect(() => {
    if (!date || !time) return;
    const currentMoment = moment();
    const momentTime = moment.unix(time).utc();
    const hourFormatted = moment.unix(time).format(timeFormat);
    const activeSlots = slots.filter((slot) => !slot.disabled);

    if (activeSlots.length > 0) {
      const filteredSlots = activeSlots
        .filter((slot) => moment.unix(slot.dt).format(timeFormat) === hourFormatted);

      if (filteredSlots.length > 0) {
        setTime(filteredSlots[0].dt);
      } else if (moment(momentTime.format(timeFormat), timeFormat).isBetween(moment('06:00', timeFormat), moment(currentMoment.format(timeFormat), timeFormat))) {
        setTime(activeSlots[0].dt);
      } else {
        setTime(activeSlots[activeSlots.length - 1].dt);
      }
    }
  }, [date, slots]);

  useEffect(() => (() => {
    if (slotsRequest !== null) {
      slotsRequest.source.cancel();
    }
  }), []);

  useEffect(() => {
    (new RequestService('client/places/shifts'))
      .setParams({
        place_id: restaurantId,
      })
      .send()
      .then((response) => {
        setShifts(response.data);
      })
      .catch(() => null);
  }, []);

  const clickedMinusHandler = () => {
    const newCount = count === 1 ? 1 : (count - 1);
    return setCount(newCount);
  };

  const clickedPlusHandler = () => setCount(count + 1);

  const dateHandler = (event) => setDate(event);

  let secondLanguage = lang;
  if (lang === 'en') {
    secondLanguage = restaurantInfo.city.country.code.toLowerCase();
  }

  return (
    <form className={styles['booking-info-block']} noValidate>
      <div className={styles['powered-by']}>
        {t('title')}
        {lang && (
        <LanguageSwitcher locales={['en', secondLanguage]} />
        )}
      </div>
      {showSuccess ? (
        <>
          <h1 className="success" style={{ textAlign: 'center', margin: '40px 0' }}>{t('forms:booking_success')}</h1>
          {booking.status === 'active'
            && booking.transactions?.find((transaction) => transaction.status === 'pending')?.id
            && (
              <>
                <p className="text-tasting"><strong>{t('pageBookings:transactions_text')}</strong></p>
                <ButtonWrapper type="outline">
                  {t('pageBookings:pay')}
                  <a target="_blank" rel="noreferrer" href={`https://${process.env.REACT_APP_DOMAIN}/booking/${booking.id}/${booking.pnr}`}>
                    {' '}
                  </a>
                </ButtonWrapper>
              </>
            )}

          <Button
            text={t('book_again')}
            onClick={() => {
              setShowSuccess(false);
              setDate(null);
              setTime(null);
              setCount(0);
              setBooking({});
            }}
            classes=""
          />
        </>
      ) : (
        <>
          <div className={styles['booking-header']}>
            <DatePicker
              date={date}
              handleDate={(event) => dateHandler(event)}
            />
            <div className={styles['guests-wrapper']}>
              <User className={styles['icon']} />
              <Counter
                count={count}
                clickedMinus={clickedMinusHandler}
                clickedPlus={clickedPlusHandler}
              />
            </div>
            <Tooltip
              title={onlyInside || onlyOutside ? t('pageBookings:seating_tooltip_inside') : t('pageBookings:seating_tooltip')}
              position="bottom"
            >
              <button
                type="button"
                className={`${styles['toggle-seating']} ${seating ? styles['active'] : ''}`}
                disabled={onlyInside || onlyOutside}
                onClick={() => setSeating(!seating)}
                data-testid="toggle-seating"
              >
                <span data-testid="seating-inside">
                  {t('pageBookings:inside')}
                </span>
                <span data-testid="seating-outside">
                  {t('pageBookings:outside')}
                </span>
              </button>
            </Tooltip>
          </div>
          {date === null || count === 0 ? (
            <h3 className={styles['please-select']}>{t('pageBookings:select_date_and_guests')}</h3>
          ) : (
            <div className={`${styles['pick-hour']} ${loading ? styles['loading'] : ''}`}>
              {slots.length < 1 && (
                <h3 className="section-sub-title"><strong>{t('pageBookings:no_time_slots')}</strong></h3>
              )}
              <div className={styles['hours-wrapper']}>
                {slots.map((slot) => (
                  <button
                    type="button"
                    key={slot.dt}
                    className={(slot.dt === time && !slot.disabled) ? styles['selected-hour'] : ''}
                    onClick={() => {
                      setTime(slot.dt);
                    }}
                    disabled={slot.disabled}
                  >
                    <span className={styles['hour']}>{moment.unix(slot.dt).utc().format(timeFormat)}</span>
                  </button>
                ))}
              </div>
            </div>
          )}
          <div style={{ marginTop: 30, marginBottom: 30 }}>
            <ShiftNotesAndRules
              shifts={shifts}
              date={date}
              time={time}
              count={count}
              restaurantInfo={restaurantInfo}
            />
          </div>
          <BookingFormFields
            time={time}
            guests={count}
            setShowSuccess={setShowSuccess}
            setBooking={setBooking}
            outside={seating}
            restaurantId={restaurantId}
            lang={secondLanguage}
          />
        </>
      )}
    </form>
  );
}

BookRestaurantForm.propTypes = {
  restaurantId: PropTypes.string.isRequired,
  restaurantInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Place)]).isRequired,
  lang: PropTypes.string,
};

BookRestaurantForm.defaultProps = {
  lang: null,
};
